// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-corporates-js": () => import("./../src/pages/corporates.js" /* webpackChunkName: "component---src-pages-corporates-js" */),
  "component---src-pages-discover-js": () => import("./../src/pages/discover.js" /* webpackChunkName: "component---src-pages-discover-js" */),
  "component---src-pages-engage-js": () => import("./../src/pages/engage.js" /* webpackChunkName: "component---src-pages-engage-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-individuals-js": () => import("./../src/pages/individuals.js" /* webpackChunkName: "component---src-pages-individuals-js" */),
  "component---src-pages-kids-js": () => import("./../src/pages/kids.js" /* webpackChunkName: "component---src-pages-kids-js" */),
  "component---src-pages-ourstory-js": () => import("./../src/pages/ourstory.js" /* webpackChunkName: "component---src-pages-ourstory-js" */),
  "component---src-pages-page-2-js": () => import("./../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */)
}

